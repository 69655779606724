import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~3],
		"/admin": [6],
		"/admin/check-klarna-captures": [~7],
		"/admin/dashboards/customers": [~8],
		"/admin/dashboards/finance": [~9],
		"/admin/dashboards/operations": [~10],
		"/admin/dashboards/store": [~11],
		"/admin/klarna-order-info": [~12],
		"/admin/orders": [~13],
		"/admin/orders/[id]/shipping-labels": [~14],
		"/admin/sales": [~15],
		"/admin/start": [16],
		"/aktuellt-i-butiken": [17],
		"/betalning/bekraftelse-klarna": [~18],
		"/billiga_mattor_till_kampanjpris": [~19],
		"/cookie-policy": [20],
		"/integritetspolicy": [21],
		"/kassa": [22],
		"/kontakta-oss-i-boras": [23],
		"/kopa-matta-online": [24],
		"/leverans/qr/[tracking_id]": [~25],
		"/mattbloggen": [26,[],[2]],
		"/mattbloggen/[post]": [27,[],[2]],
		"/mattor-inspiration": [~28],
		"/mattor-rea": [~29],
		"/mattor_nyheter": [~30],
		"/om-nylanders-mattor": [31],
		"/populara_mattor": [~32],
		"/scripts/send-review-requests/email": [~33],
		"/sok-mattor": [~34],
		"/tack": [~35],
		"/tisca-ullmattor": [36],
		"/ullmatta-i-eget-matt": [37],
		"/villkor": [38],
		"/[category]": [4],
		"/[category]/[product]": [5]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';