import { handleErrorWithSentry, Replay } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";

// Initialize Sentry, add the timeout to fix a bug
setTimeout(() => {
    Sentry.init({
        dsn: "https://375e65e418c2d62c561127e3c978ab0c@o4505675516018688.ingest.sentry.io/4505675519492096",
        tracesSampleRate: 1.0,
        environment: import.meta.env.MODE,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        // If you don't want to use Session Replay, just remove the line below:
        // integrations: [new Replay()],
    });
}, 100);

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
